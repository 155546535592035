.form--login {
    display: inline-block;
    margin: 0 auto;
    text-align: left;
    padding-top: 5em;
}

.form--login > fieldset > input[type="email"],
.form--login > fieldset > input[type="password"] {
    width: 25em;
}

input[name="remember"] {
    margin: 1em 0.5em 0 0;
}